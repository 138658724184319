import * as React from "react";
import * as styles from "./header.module.scss";
import {Link} from "gatsby";

const Header = ({isBg})=>{


  return (
    <header className={`${styles.header} ${isBg ? styles.bg : ''}`}>
      <div className={styles.headerWrap}>
        <a className={styles.logo} href={'/'}>
          <svg viewBox="0 0 78 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.400425 11.609V2.7689H1.95207V3.53098H2.17731C2.31913 3.28542 2.5402 3.0695 2.84052 2.88321C3.14084 2.68846 3.57046 2.59108 4.12938 2.59108C4.62992 2.59108 5.09291 2.71809 5.51836 2.97212C5.94381 3.21768 6.28584 3.58178 6.54445 4.06444C6.80306 4.54709 6.93236 5.13135 6.93236 5.81722V6.02045C6.93236 6.70632 6.80306 7.29058 6.54445 7.77324C6.28584 8.25589 5.94381 8.62423 5.51836 8.87825C5.09291 9.12381 4.62992 9.24659 4.12938 9.24659C3.75399 9.24659 3.43698 9.20002 3.17838 9.10688C2.92811 9.0222 2.72373 8.91212 2.56522 8.77664C2.41506 8.63269 2.2941 8.48874 2.20234 8.3448H1.9771V11.609H0.400425ZM3.65388 7.84944C4.14607 7.84944 4.55067 7.69279 4.86767 7.37949C5.19301 7.05773 5.35569 6.59201 5.35569 5.98234V5.85533C5.35569 5.24566 5.19301 4.78418 4.86767 4.47088C4.54232 4.14911 4.13773 3.98823 3.65388 3.98823C3.17003 3.98823 2.76544 4.14911 2.44009 4.47088C2.11475 4.78418 1.95207 5.24566 1.95207 5.85533V5.98234C1.95207 6.59201 2.11475 7.05773 2.44009 7.37949C2.76544 7.69279 3.17003 7.84944 3.65388 7.84944Z" fill="black"/>
            <path d="M8.38009 9.06877V2.7689H9.93174V3.48017H10.157C10.2487 3.22615 10.3989 3.03986 10.6075 2.92131C10.8244 2.80277 11.0746 2.74349 11.3583 2.74349H12.109V4.16605H11.3332C10.9328 4.16605 10.6033 4.27613 10.3447 4.49628C10.0861 4.70797 9.95676 5.03821 9.95676 5.48699V9.06877H8.38009Z" fill="black"/>
            <path d="M16.0746 9.24659C15.4573 9.24659 14.9025 9.11958 14.4103 8.86555C13.9181 8.61152 13.5302 8.24319 13.2466 7.76053C12.9629 7.27788 12.8211 6.69785 12.8211 6.02045V5.81722C12.8211 5.13982 12.9629 4.55979 13.2466 4.07714C13.5302 3.59449 13.9181 3.22615 14.4103 2.97212C14.9025 2.71809 15.4573 2.59108 16.0746 2.59108C16.6919 2.59108 17.2467 2.71809 17.7389 2.97212C18.231 3.22615 18.619 3.59449 18.9026 4.07714C19.1862 4.55979 19.328 5.13982 19.328 5.81722V6.02045C19.328 6.69785 19.1862 7.27788 18.9026 7.76053C18.619 8.24319 18.231 8.61152 17.7389 8.86555C17.2467 9.11958 16.6919 9.24659 16.0746 9.24659ZM16.0746 7.82404C16.5584 7.82404 16.9589 7.66739 17.2759 7.35409C17.5929 7.03232 17.7514 6.57507 17.7514 5.98234V5.85533C17.7514 5.2626 17.5929 4.80958 17.2759 4.49628C16.9672 4.17451 16.5668 4.01363 16.0746 4.01363C15.5907 4.01363 15.1903 4.17451 14.8733 4.49628C14.5563 4.80958 14.3978 5.2626 14.3978 5.85533V5.98234C14.3978 6.57507 14.5563 7.03232 14.8733 7.35409C15.1903 7.66739 15.5907 7.82404 16.0746 7.82404Z" fill="black"/>
            <path d="M21.6017 9.06877V4.08984H20.0251V2.7689H21.6017V1.60037C21.6017 1.16853 21.7269 0.825589 21.9771 0.571562C22.2358 0.309067 22.5694 0.177819 22.9782 0.177819H24.6049V1.49876H23.5288C23.2952 1.49876 23.1784 1.62577 23.1784 1.8798V2.7689H24.8051V4.08984H23.1784V9.06877H21.6017Z" fill="black"/>
            <path d="M28.646 9.24659C28.0287 9.24659 27.4823 9.11535 27.0068 8.85285C26.5396 8.58189 26.1726 8.20508 25.9056 7.72243C25.647 7.23131 25.5177 6.65552 25.5177 5.99504V5.84263C25.5177 5.18216 25.647 4.6106 25.9056 4.12794C26.1642 3.63682 26.5271 3.26002 26.9943 2.99752C27.4615 2.72656 28.0037 2.59108 28.621 2.59108C29.23 2.59108 29.7597 2.73079 30.2102 3.01022C30.6607 3.28119 31.0111 3.66223 31.2613 4.15335C31.5116 4.636 31.6367 5.19909 31.6367 5.84263V6.38879H27.1194C27.1361 6.82063 27.2946 7.17204 27.5949 7.443C27.8952 7.71396 28.2623 7.84944 28.6961 7.84944C29.1382 7.84944 29.4636 7.75207 29.6721 7.55731C29.8807 7.36256 30.0392 7.14663 30.1476 6.90954L31.4365 7.59542C31.3197 7.81557 31.1487 8.0569 30.9235 8.31939C30.7066 8.57342 30.4146 8.79358 30.0475 8.97987C29.6805 9.15768 29.2133 9.24659 28.646 9.24659ZM27.1319 5.19486H30.035C30.0016 4.83075 29.8557 4.53862 29.5971 4.31846C29.3468 4.09831 29.0173 3.98823 28.6085 3.98823C28.1831 3.98823 27.8452 4.09831 27.5949 4.31846C27.3447 4.53862 27.1903 4.83075 27.1319 5.19486Z" fill="black"/>
            <path d="M35.6178 9.24659C34.8086 9.24659 34.1454 9.06877 33.6282 8.71314C33.111 8.3575 32.7981 7.84944 32.6897 7.18897L34.1412 6.80793C34.1996 7.1043 34.2956 7.33716 34.429 7.50651C34.5709 7.67586 34.7419 7.79864 34.9421 7.87485C35.1506 7.94259 35.3759 7.97646 35.6178 7.97646C35.9849 7.97646 36.256 7.91295 36.4312 7.78594C36.6063 7.65046 36.6939 7.48534 36.6939 7.29058C36.6939 7.09583 36.6105 6.94765 36.4437 6.84604C36.2768 6.73596 36.0099 6.64705 35.6428 6.57931L35.2924 6.5158C34.8587 6.43112 34.4624 6.31681 34.1037 6.17286C33.745 6.02045 33.4572 5.81299 33.2403 5.5505C33.0234 5.288 32.9149 4.9493 32.9149 4.53439C32.9149 3.90779 33.1402 3.42937 33.5906 3.09913C34.0411 2.76043 34.6334 2.59108 35.3675 2.59108C36.0599 2.59108 36.6355 2.74773 37.0944 3.06103C37.5532 3.37433 37.8535 3.78501 37.9953 4.29306L36.5313 4.75031C36.4645 4.42854 36.3269 4.19992 36.1183 4.06444C35.9181 3.92896 35.6678 3.86121 35.3675 3.86121C35.0672 3.86121 34.8378 3.91625 34.6793 4.02633C34.5208 4.12794 34.4416 4.27189 34.4416 4.45818C34.4416 4.6614 34.525 4.81382 34.6918 4.91543C34.8587 5.00857 35.0839 5.08055 35.3675 5.13135L35.7179 5.19486C36.1851 5.27953 36.6063 5.39385 36.9817 5.53779C37.3655 5.67328 37.6658 5.87226 37.8827 6.13476C38.1079 6.38879 38.2206 6.73596 38.2206 7.17627C38.2206 7.83674 37.9828 8.34903 37.5073 8.71314C37.0401 9.06877 36.4103 9.24659 35.6178 9.24659Z" fill="black"/>
            <path d="M42.1799 9.24659C41.3707 9.24659 40.7075 9.06877 40.1903 8.71314C39.6731 8.3575 39.3603 7.84944 39.2518 7.18897L40.7034 6.80793C40.7618 7.1043 40.8577 7.33716 40.9912 7.50651C41.133 7.67586 41.304 7.79864 41.5042 7.87485C41.7128 7.94259 41.938 7.97646 42.1799 7.97646C42.547 7.97646 42.8181 7.91295 42.9933 7.78594C43.1685 7.65046 43.2561 7.48534 43.2561 7.29058C43.2561 7.09583 43.1727 6.94765 43.0058 6.84604C42.839 6.73596 42.572 6.64705 42.205 6.57931L41.8546 6.5158C41.4208 6.43112 41.0245 6.31681 40.6658 6.17286C40.3071 6.02045 40.0193 5.81299 39.8024 5.5505C39.5855 5.288 39.4771 4.9493 39.4771 4.53439C39.4771 3.90779 39.7023 3.42937 40.1528 3.09913C40.6033 2.76043 41.1956 2.59108 41.9297 2.59108C42.6221 2.59108 43.1977 2.74773 43.6565 3.06103C44.1153 3.37433 44.4156 3.78501 44.5575 4.29306L43.0934 4.75031C43.0267 4.42854 42.889 4.19992 42.6805 4.06444C42.4803 3.92896 42.23 3.86121 41.9297 3.86121C41.6294 3.86121 41.3999 3.91625 41.2414 4.02633C41.0829 4.12794 41.0037 4.27189 41.0037 4.45818C41.0037 4.6614 41.0871 4.81382 41.254 4.91543C41.4208 5.00857 41.646 5.08055 41.9297 5.13135L42.28 5.19486C42.7472 5.27953 43.1685 5.39385 43.5439 5.53779C43.9276 5.67328 44.2279 5.87226 44.4448 6.13476C44.6701 6.38879 44.7827 6.73596 44.7827 7.17627C44.7827 7.83674 44.5449 8.34903 44.0694 8.71314C43.6023 9.06877 42.9724 9.24659 42.1799 9.24659Z" fill="black"/>
            <path d="M46.1643 9.06877V2.7689H47.741V9.06877H46.1643ZM46.9527 2.03222C46.6691 2.03222 46.4271 1.93907 46.2269 1.75279C46.035 1.5665 45.9391 1.32094 45.9391 1.01611C45.9391 0.711277 46.035 0.465717 46.2269 0.27943C46.4271 0.0931434 46.6691 0 46.9527 0C47.2447 0 47.4866 0.0931434 47.6785 0.27943C47.8703 0.465717 47.9663 0.711277 47.9663 1.01611C47.9663 1.32094 47.8703 1.5665 47.6785 1.75279C47.4866 1.93907 47.2447 2.03222 46.9527 2.03222Z" fill="black"/>
            <path d="M52.4413 9.24659C51.824 9.24659 51.2692 9.11958 50.7771 8.86555C50.2849 8.61152 49.897 8.24319 49.6133 7.76053C49.3297 7.27788 49.1879 6.69785 49.1879 6.02045V5.81722C49.1879 5.13982 49.3297 4.55979 49.6133 4.07714C49.897 3.59449 50.2849 3.22615 50.7771 2.97212C51.2692 2.71809 51.824 2.59108 52.4413 2.59108C53.0586 2.59108 53.6134 2.71809 54.1056 2.97212C54.5978 3.22615 54.9857 3.59449 55.2693 4.07714C55.553 4.55979 55.6948 5.13982 55.6948 5.81722V6.02045C55.6948 6.69785 55.553 7.27788 55.2693 7.76053C54.9857 8.24319 54.5978 8.61152 54.1056 8.86555C53.6134 9.11958 53.0586 9.24659 52.4413 9.24659ZM52.4413 7.82404C52.9252 7.82404 53.3256 7.66739 53.6426 7.35409C53.9596 7.03232 54.1181 6.57507 54.1181 5.98234V5.85533C54.1181 5.2626 53.9596 4.80958 53.6426 4.49628C53.3339 4.17451 52.9335 4.01363 52.4413 4.01363C51.9575 4.01363 51.557 4.17451 51.24 4.49628C50.923 4.80958 50.7645 5.2626 50.7645 5.85533V5.98234C50.7645 6.57507 50.923 7.03232 51.24 7.35409C51.557 7.66739 51.9575 7.82404 52.4413 7.82404Z" fill="black"/>
            <path d="M57.1501 9.06877V2.7689H58.7018V3.59449H58.927C59.0271 3.37433 59.2148 3.16687 59.4901 2.97212C59.7654 2.7689 60.1825 2.66729 60.7414 2.66729C61.2253 2.66729 61.6466 2.7816 62.0053 3.01022C62.3723 3.23038 62.656 3.53945 62.8562 3.93742C63.0564 4.32693 63.1565 4.78418 63.1565 5.30917V9.06877H61.5798V5.43618C61.5798 4.962 61.463 4.60636 61.2295 4.36927C61.0042 4.13218 60.6789 4.01363 60.2534 4.01363C59.7696 4.01363 59.3942 4.17875 59.1272 4.50898C58.8603 4.83075 58.7268 5.28377 58.7268 5.86803V9.06877H57.1501Z" fill="black"/>
            <path d="M66.788 9.24659C66.3459 9.24659 65.9496 9.17039 65.5992 9.01797C65.2489 8.85708 64.9694 8.62846 64.7608 8.3321C64.5606 8.02726 64.4605 7.65892 64.4605 7.22708C64.4605 6.79523 64.5606 6.43536 64.7608 6.14746C64.9694 5.8511 65.253 5.63094 65.6117 5.48699C65.9788 5.33457 66.3959 5.25836 66.8631 5.25836H68.5649V4.90273C68.5649 4.60636 68.4731 4.36504 68.2896 4.17875C68.1061 3.98399 67.8141 3.88662 67.4137 3.88662C67.0216 3.88662 66.7296 3.97976 66.5377 4.16605C66.3459 4.34387 66.2207 4.57673 66.1623 4.86462L64.7108 4.36927C64.8109 4.0475 64.9694 3.75537 65.1863 3.49288C65.4115 3.22191 65.7077 3.00599 66.0747 2.84511C66.4501 2.67575 66.9048 2.59108 67.4387 2.59108C68.2562 2.59108 68.9027 2.79853 69.3783 3.21344C69.8538 3.62836 70.0915 4.22955 70.0915 5.01704V7.36679C70.0915 7.62082 70.2083 7.74783 70.4419 7.74783H70.9424V9.06877H69.8913C69.5826 9.06877 69.3282 8.99257 69.128 8.84015C68.9278 8.68773 68.8277 8.48451 68.8277 8.23048V8.21778H68.5899C68.5565 8.31939 68.4815 8.45487 68.3647 8.62423C68.2479 8.78511 68.0644 8.92906 67.8141 9.05607C67.5638 9.18309 67.2218 9.24659 66.788 9.24659ZM67.0633 7.95105C67.5054 7.95105 67.8641 7.82827 68.1394 7.58271C68.4231 7.32869 68.5649 6.99422 68.5649 6.57931V6.45229H66.9757C66.6837 6.45229 66.4543 6.5158 66.2875 6.64281C66.1206 6.76983 66.0372 6.94765 66.0372 7.17627C66.0372 7.4049 66.1248 7.59118 66.3 7.73513C66.4752 7.87908 66.7296 7.95105 67.0633 7.95105Z" fill="black"/>
            <path d="M72.0952 9.06877V0.177819H73.6719V9.06877H72.0952Z" fill="black"/>
            <path d="M2.90308 25.1233C2.41089 25.1233 1.9479 25.0005 1.51411 24.755C1.08866 24.5009 0.746626 24.1326 0.488018 23.6499C0.22941 23.1673 0.100106 22.583 0.100106 21.8972V21.6939C0.100106 21.0081 0.22941 20.4238 0.488018 19.9411C0.746626 19.4585 1.08866 19.0944 1.51411 18.8488C1.93956 18.5948 2.40255 18.4678 2.90308 18.4678C3.27848 18.4678 3.59131 18.5144 3.84158 18.6075C4.10019 18.6922 4.30874 18.8023 4.46724 18.9377C4.62575 19.0732 4.74671 19.2172 4.83013 19.3696H5.05537V16.0545H6.63204V24.9455H5.08039V24.1834H4.85516C4.71334 24.4205 4.49227 24.6364 4.19195 24.8312C3.89997 25.0259 3.47035 25.1233 2.90308 25.1233ZM3.37859 23.7261C3.86243 23.7261 4.26703 23.5695 4.59238 23.2562C4.91772 22.9344 5.08039 22.4687 5.08039 21.859V21.732C5.08039 21.1224 4.91772 20.6609 4.59238 20.3476C4.27537 20.0258 3.87078 19.8649 3.37859 19.8649C2.89474 19.8649 2.49014 20.0258 2.1648 20.3476C1.83945 20.6609 1.67678 21.1224 1.67678 21.732V21.859C1.67678 22.4687 1.83945 22.9344 2.1648 23.2562C2.49014 23.5695 2.89474 23.7261 3.37859 23.7261Z" fill="black"/>
            <path d="M11.2081 25.1233C10.5908 25.1233 10.0444 24.992 9.56885 24.7296C9.10169 24.4586 8.73463 24.0818 8.46768 23.5991C8.20907 23.108 8.07977 22.5322 8.07977 21.8717V21.7193C8.07977 21.0589 8.20907 20.4873 8.46768 20.0046C8.72629 19.5135 9.08918 19.1367 9.55634 18.8742C10.0235 18.6033 10.5657 18.4678 11.1831 18.4678C11.792 18.4678 12.3218 18.6075 12.7723 18.8869C13.2227 19.1579 13.5731 19.5389 13.8234 20.0301C14.0736 20.5127 14.1988 21.0758 14.1988 21.7193V22.2655H9.68147C9.69816 22.6973 9.85666 23.0487 10.157 23.3197C10.4573 23.5907 10.8244 23.7261 11.2581 23.7261C11.7003 23.7261 12.0256 23.6288 12.2342 23.434C12.4427 23.2393 12.6012 23.0233 12.7097 22.7862L13.9986 23.4721C13.8818 23.6923 13.7107 23.9336 13.4855 24.1961C13.2686 24.4501 12.9766 24.6703 12.6096 24.8566C12.2425 25.0344 11.7754 25.1233 11.2081 25.1233ZM9.69398 21.0716H12.5971C12.5637 20.7075 12.4177 20.4153 12.1591 20.1952C11.9088 19.975 11.5793 19.8649 11.1706 19.8649C10.7451 19.8649 10.4072 19.975 10.157 20.1952C9.90671 20.4153 9.75238 20.7075 9.69398 21.0716Z" fill="black"/>
            <path d="M16.7202 24.9455L14.7431 18.6456H16.4199L17.8589 23.777H18.0841L19.5232 18.6456H21.2L19.2229 24.9455H16.7202Z" fill="black"/>
            <path d="M24.9189 25.1233C24.3016 25.1233 23.7552 24.992 23.2797 24.7296C22.8125 24.4586 22.4455 24.0818 22.1785 23.5991C21.9199 23.108 21.7906 22.5322 21.7906 21.8717V21.7193C21.7906 21.0589 21.9199 20.4873 22.1785 20.0046C22.4371 19.5135 22.8 19.1367 23.2672 18.8742C23.7343 18.6033 24.2766 18.4678 24.8939 18.4678C25.5029 18.4678 26.0326 18.6075 26.4831 18.8869C26.9336 19.1579 27.2839 19.5389 27.5342 20.0301C27.7845 20.5127 27.9096 21.0758 27.9096 21.7193V22.2655H23.3923C23.409 22.6973 23.5675 23.0487 23.8678 23.3197C24.1681 23.5907 24.5352 23.7261 24.969 23.7261C25.4111 23.7261 25.7365 23.6288 25.945 23.434C26.1536 23.2393 26.3121 23.0233 26.4205 22.7862L27.7094 23.4721C27.5926 23.6923 27.4216 23.9336 27.1964 24.1961C26.9795 24.4501 26.6875 24.6703 26.3204 24.8566C25.9534 25.0344 25.4862 25.1233 24.9189 25.1233ZM23.4048 21.0716H26.3079C26.2745 20.7075 26.1286 20.4153 25.8699 20.1952C25.6197 19.975 25.2902 19.8649 24.8814 19.8649C24.4559 19.8649 24.1181 19.975 23.8678 20.1952C23.6176 20.4153 23.4632 20.7075 23.4048 21.0716Z" fill="black"/>
            <path d="M29.313 24.9455V16.0545H30.8896V24.9455H29.313Z" fill="black"/>
            <path d="M35.5899 25.1233C34.9726 25.1233 34.4179 24.9963 33.9257 24.7423C33.4335 24.4882 33.0456 24.1199 32.7619 23.6372C32.4783 23.1546 32.3365 22.5746 32.3365 21.8972V21.6939C32.3365 21.0165 32.4783 20.4365 32.7619 19.9538C33.0456 19.4712 33.4335 19.1029 33.9257 18.8488C34.4179 18.5948 34.9726 18.4678 35.5899 18.4678C36.2073 18.4678 36.762 18.5948 37.2542 18.8488C37.7464 19.1029 38.1343 19.4712 38.4179 19.9538C38.7016 20.4365 38.8434 21.0165 38.8434 21.6939V21.8972C38.8434 22.5746 38.7016 23.1546 38.4179 23.6372C38.1343 24.1199 37.7464 24.4882 37.2542 24.7423C36.762 24.9963 36.2073 25.1233 35.5899 25.1233ZM35.5899 23.7007C36.0738 23.7007 36.4742 23.5441 36.7912 23.2308C37.1082 22.909 37.2667 22.4518 37.2667 21.859V21.732C37.2667 21.1393 37.1082 20.6863 36.7912 20.373C36.4826 20.0512 36.0821 19.8903 35.5899 19.8903C35.1061 19.8903 34.7057 20.0512 34.3887 20.373C34.0717 20.6863 33.9132 21.1393 33.9132 21.732V21.859C33.9132 22.4518 34.0717 22.909 34.3887 23.2308C34.7057 23.5441 35.1061 23.7007 35.5899 23.7007Z" fill="black"/>
            <path d="M40.2987 27.4858V18.6456H41.8504V19.4077H42.0756C42.2174 19.1621 42.4385 18.9462 42.7388 18.7599C43.0392 18.5652 43.4688 18.4678 44.0277 18.4678C44.5282 18.4678 44.9912 18.5948 45.4167 18.8488C45.8421 19.0944 46.1842 19.4585 46.4428 19.9411C46.7014 20.4238 46.8307 21.0081 46.8307 21.6939V21.8972C46.8307 22.583 46.7014 23.1673 46.4428 23.6499C46.1842 24.1326 45.8421 24.5009 45.4167 24.755C44.9912 25.0005 44.5282 25.1233 44.0277 25.1233C43.6523 25.1233 43.3353 25.0767 43.0767 24.9836C42.8264 24.8989 42.622 24.7888 42.4635 24.6533C42.3134 24.5094 42.1924 24.3654 42.1007 24.2215H41.8754V27.4858H40.2987ZM43.5522 23.7261C44.0444 23.7261 44.449 23.5695 44.766 23.2562C45.0913 22.9344 45.254 22.4687 45.254 21.859V21.732C45.254 21.1224 45.0913 20.6609 44.766 20.3476C44.4406 20.0258 44.036 19.8649 43.5522 19.8649C43.0684 19.8649 42.6638 20.0258 42.3384 20.3476C42.0131 20.6609 41.8504 21.1224 41.8504 21.732V21.859C41.8504 22.4687 42.0131 22.9344 42.3384 23.2562C42.6638 23.5695 43.0684 23.7261 43.5522 23.7261Z" fill="black"/>
            <path d="M48.2784 24.9455V18.6456H49.8301V19.3315H50.0553C50.1637 19.1198 50.3431 18.9377 50.5934 18.7853C50.8436 18.6244 51.1731 18.544 51.5819 18.544C52.024 18.544 52.3786 18.6329 52.6455 18.8107C52.9125 18.9801 53.1169 19.2045 53.2587 19.4839H53.4839C53.6258 19.2129 53.826 18.9885 54.0846 18.8107C54.3432 18.6329 54.7102 18.544 55.1857 18.544C55.5695 18.544 55.9157 18.6287 56.2243 18.798C56.5413 18.9589 56.7916 19.2087 56.9751 19.5474C57.167 19.8776 57.263 20.2968 57.263 20.8048V24.9455H55.6863V20.9191C55.6863 20.572 55.5987 20.3137 55.4235 20.1444C55.2483 19.9665 55.0022 19.8776 54.6852 19.8776C54.3265 19.8776 54.047 19.9962 53.8468 20.2333C53.6549 20.4619 53.559 20.7921 53.559 21.224V24.9455H51.9823V20.9191C51.9823 20.572 51.8947 20.3137 51.7196 20.1444C51.5444 19.9665 51.2983 19.8776 50.9813 19.8776C50.6226 19.8776 50.3431 19.9962 50.1429 20.2333C49.951 20.4619 49.8551 20.7921 49.8551 21.224V24.9455H48.2784Z" fill="black"/>
            <path d="M61.7867 25.1233C61.1694 25.1233 60.623 24.992 60.1475 24.7296C59.6803 24.4586 59.3132 24.0818 59.0463 23.5991C58.7877 23.108 58.6584 22.5322 58.6584 21.8717V21.7193C58.6584 21.0589 58.7877 20.4873 59.0463 20.0046C59.3049 19.5135 59.6678 19.1367 60.1349 18.8742C60.6021 18.6033 61.1443 18.4678 61.7617 18.4678C62.3706 18.4678 62.9004 18.6075 63.3509 18.8869C63.8013 19.1579 64.1517 19.5389 64.402 20.0301C64.6522 20.5127 64.7774 21.0758 64.7774 21.7193V22.2655H60.2601C60.2768 22.6973 60.4353 23.0487 60.7356 23.3197C61.0359 23.5907 61.403 23.7261 61.8368 23.7261C62.2789 23.7261 62.6042 23.6288 62.8128 23.434C63.0213 23.2393 63.1798 23.0233 63.2883 22.7862L64.5772 23.4721C64.4604 23.6923 64.2894 23.9336 64.0641 24.1961C63.8472 24.4501 63.5552 24.6703 63.1882 24.8566C62.8211 25.0344 62.354 25.1233 61.7867 25.1233ZM60.2726 21.0716H63.1757C63.1423 20.7075 62.9963 20.4153 62.7377 20.1952C62.4874 19.975 62.1579 19.8649 61.7492 19.8649C61.3237 19.8649 60.9858 19.975 60.7356 20.1952C60.4853 20.4153 60.331 20.7075 60.2726 21.0716Z" fill="black"/>
            <path d="M66.1807 24.9455V18.6456H67.7324V19.4712H67.9576C68.0577 19.251 68.2454 19.0436 68.5207 18.8488C68.796 18.6456 69.2131 18.544 69.772 18.544C70.2559 18.544 70.6772 18.6583 71.0359 18.8869C71.4029 19.1071 71.6866 19.4162 71.8868 19.8141C72.087 20.2036 72.1871 20.6609 72.1871 21.1859V24.9455H70.6104V21.3129C70.6104 20.8387 70.4936 20.4831 70.26 20.246C70.0348 20.0089 69.7095 19.8903 69.284 19.8903C68.8002 19.8903 68.4248 20.0555 68.1578 20.3857C67.8909 20.7075 67.7574 21.1605 67.7574 21.7447V24.9455H66.1807Z" fill="black"/>
            <path d="M76.098 24.9455C75.6892 24.9455 75.3555 24.8185 75.0969 24.5644C74.8467 24.3019 74.7215 23.9548 74.7215 23.5229V19.9665H73.1699V18.6456H74.7215V16.6896H76.2982V18.6456H78V19.9665H76.2982V23.2435C76.2982 23.4975 76.415 23.6245 76.6486 23.6245H77.8498V24.9455H76.098Z" fill="black"/>
            <path d="M2.32747 41C1.88534 41 1.48908 40.9238 1.13871 40.7714C0.788337 40.6105 0.508874 40.3819 0.300319 40.0855C0.100106 39.7807 0 39.4123 0 38.9805C0 38.5486 0.100106 38.1888 0.300319 37.9009C0.508874 37.6045 0.792508 37.3843 1.15122 37.2404C1.51828 37.088 1.93539 37.0118 2.40255 37.0118H4.10436V36.6561C4.10436 36.3598 4.01259 36.1184 3.82907 35.9322C3.64554 35.7374 3.35356 35.64 2.95314 35.64C2.56105 35.64 2.26908 35.7332 2.07721 35.9195C1.88534 36.0973 1.7602 36.3301 1.70181 36.618L0.250266 36.1227C0.350372 35.8009 0.508874 35.5088 0.725771 35.2463C0.95101 34.9753 1.24716 34.7594 1.61421 34.5985C1.98961 34.4292 2.44426 34.3445 2.97816 34.3445C3.7957 34.3445 4.44222 34.5519 4.91772 34.9669C5.39323 35.3818 5.63098 35.983 5.63098 36.7705V39.1202C5.63098 39.3742 5.74777 39.5012 5.98135 39.5012H6.48188V40.8222H5.43077C5.12211 40.8222 4.86767 40.746 4.66746 40.5936C4.46724 40.4411 4.36714 40.2379 4.36714 39.9839V39.9712H4.12938C4.09602 40.0728 4.02094 40.2083 3.90415 40.3776C3.78735 40.5385 3.60383 40.6825 3.35356 40.8095C3.1033 40.9365 2.76127 41 2.32747 41ZM2.60276 39.7045C3.0449 39.7045 3.40361 39.5817 3.67891 39.3361C3.96254 39.0821 4.10436 38.7476 4.10436 38.3327V38.2057H2.51517C2.22319 38.2057 1.99378 38.2692 1.82694 38.3962C1.6601 38.5232 1.57667 38.7011 1.57667 38.9297C1.57667 39.1583 1.66427 39.3446 1.83945 39.4885C2.01464 39.6325 2.26908 39.7045 2.60276 39.7045Z" fill="black"/>
            <path d="M10.1635 41C9.35433 41 8.69113 40.8222 8.17391 40.4665C7.6567 40.1109 7.34387 39.6029 7.23542 38.9424L8.68696 38.5613C8.74535 38.8577 8.84129 39.0906 8.97476 39.2599C9.11658 39.4293 9.2876 39.552 9.48781 39.6283C9.69636 39.696 9.9216 39.7299 10.1635 39.7299C10.5306 39.7299 10.8017 39.6664 10.9769 39.5393C11.1521 39.4039 11.2397 39.2387 11.2397 39.044C11.2397 38.8492 11.1562 38.7011 10.9894 38.5994C10.8226 38.4894 10.5556 38.4005 10.1886 38.3327L9.83818 38.2692C9.40439 38.1845 9.00813 38.0702 8.64942 37.9263C8.2907 37.7739 8.0029 37.5664 7.786 37.3039C7.5691 37.0414 7.46066 36.7027 7.46066 36.2878C7.46066 35.6612 7.68589 35.1828 8.13637 34.8525C8.58685 34.5138 9.17915 34.3445 9.91326 34.3445C10.6057 34.3445 11.1813 34.5011 11.6401 34.8144C12.0989 35.1277 12.3992 35.5384 12.541 36.0465L11.077 36.5037C11.0103 36.1819 10.8726 35.9533 10.6641 35.8178C10.4638 35.6824 10.2136 35.6146 9.91326 35.6146C9.61294 35.6146 9.38353 35.6697 9.22503 35.7797C9.06653 35.8814 8.98728 36.0253 8.98728 36.2116C8.98728 36.4148 9.0707 36.5672 9.23754 36.6688C9.40439 36.762 9.62963 36.834 9.91326 36.8848L10.2636 36.9483C10.7308 37.0329 11.1521 37.1473 11.5275 37.2912C11.9112 37.4267 12.2115 37.6257 12.4284 37.8882C12.6537 38.1422 12.7663 38.4894 12.7663 38.9297C12.7663 39.5902 12.5285 40.1024 12.053 40.4665C11.5859 40.8222 10.956 41 10.1635 41Z" fill="black"/>
            <path d="M16.7257 41C15.9165 41 15.2533 40.8222 14.7361 40.4665C14.2188 40.1109 13.906 39.6029 13.7976 38.9424L15.2491 38.5613C15.3075 38.8577 15.4034 39.0906 15.5369 39.2599C15.6787 39.4293 15.8497 39.552 16.05 39.6283C16.2585 39.696 16.4837 39.7299 16.7257 39.7299C17.0927 39.7299 17.3638 39.6664 17.539 39.5393C17.7142 39.4039 17.8018 39.2387 17.8018 39.044C17.8018 38.8492 17.7184 38.7011 17.5515 38.5994C17.3847 38.4894 17.1178 38.4005 16.7507 38.3327L16.4003 38.2692C15.9665 38.1845 15.5703 38.0702 15.2116 37.9263C14.8528 37.7739 14.565 37.5664 14.3481 37.3039C14.1312 37.0414 14.0228 36.7027 14.0228 36.2878C14.0228 35.6612 14.248 35.1828 14.6985 34.8525C15.149 34.5138 15.7413 34.3445 16.4754 34.3445C17.1678 34.3445 17.7434 34.5011 18.2022 34.8144C18.6611 35.1277 18.9614 35.5384 19.1032 36.0465L17.6391 36.5037C17.5724 36.1819 17.4348 35.9533 17.2262 35.8178C17.026 35.6824 16.7757 35.6146 16.4754 35.6146C16.1751 35.6146 15.9457 35.6697 15.7872 35.7797C15.6287 35.8814 15.5494 36.0253 15.5494 36.2116C15.5494 36.4148 15.6328 36.5672 15.7997 36.6688C15.9665 36.762 16.1918 36.834 16.4754 36.8848L16.8258 36.9483C17.2929 37.0329 17.7142 37.1473 18.0896 37.2912C18.4734 37.4267 18.7737 37.6257 18.9906 37.8882C19.2158 38.1422 19.3284 38.4894 19.3284 38.9297C19.3284 39.5902 19.0907 40.1024 18.6152 40.4665C18.148 40.8222 17.5182 41 16.7257 41Z" fill="black"/>
            <path d="M20.7101 40.8222V34.5223H22.2867V40.8222H20.7101ZM21.4984 33.7856C21.2148 33.7856 20.9729 33.6925 20.7726 33.5062C20.5808 33.3199 20.4848 33.0743 20.4848 32.7695C20.4848 32.4647 20.5808 32.2191 20.7726 32.0328C20.9729 31.8465 21.2148 31.7534 21.4984 31.7534C21.7904 31.7534 22.0323 31.8465 22.2242 32.0328C22.4161 32.2191 22.512 32.4647 22.512 32.7695C22.512 33.0743 22.4161 33.3199 22.2242 33.5062C22.0323 33.6925 21.7904 33.7856 21.4984 33.7856Z" fill="black"/>
            <path d="M26.6117 41C25.8025 41 25.1393 40.8222 24.622 40.4665C24.1048 40.1109 23.792 39.6029 23.6835 38.9424L25.1351 38.5613C25.1935 38.8577 25.2894 39.0906 25.4229 39.2599C25.5647 39.4293 25.7357 39.552 25.9359 39.6283C26.1445 39.696 26.3697 39.7299 26.6117 39.7299C26.9787 39.7299 27.2498 39.6664 27.425 39.5393C27.6002 39.4039 27.6878 39.2387 27.6878 39.044C27.6878 38.8492 27.6044 38.7011 27.4375 38.5994C27.2707 38.4894 27.0037 38.4005 26.6367 38.3327L26.2863 38.2692C25.8525 38.1845 25.4563 38.0702 25.0975 37.9263C24.7388 37.7739 24.451 37.5664 24.2341 37.3039C24.0172 37.0414 23.9088 36.7027 23.9088 36.2878C23.9088 35.6612 24.134 35.1828 24.5845 34.8525C25.035 34.5138 25.6273 34.3445 26.3614 34.3445C27.0538 34.3445 27.6294 34.5011 28.0882 34.8144C28.547 35.1277 28.8474 35.5384 28.9892 36.0465L27.5251 36.5037C27.4584 36.1819 27.3207 35.9533 27.1122 35.8178C26.912 35.6824 26.6617 35.6146 26.3614 35.6146C26.0611 35.6146 25.8317 35.6697 25.6732 35.7797C25.5147 35.8814 25.4354 36.0253 25.4354 36.2116C25.4354 36.4148 25.5188 36.5672 25.6857 36.6688C25.8525 36.762 26.0778 36.834 26.3614 36.8848L26.7118 36.9483C27.1789 37.0329 27.6002 37.1473 27.9756 37.2912C28.3593 37.4267 28.6597 37.6257 28.8766 37.8882C29.1018 38.1422 29.2144 38.4894 29.2144 38.9297C29.2144 39.5902 28.9767 40.1024 28.5012 40.4665C28.034 40.8222 27.4042 41 26.6117 41Z" fill="black"/>
            <path d="M32.8514 40.8222C32.4426 40.8222 32.1089 40.6952 31.8503 40.4411C31.6001 40.1786 31.4749 39.8315 31.4749 39.3996V35.8433H29.9233V34.5223H31.4749V32.5663H33.0516V34.5223H34.7534V35.8433H33.0516V39.1202C33.0516 39.3742 33.1684 39.5012 33.402 39.5012H34.6032V40.8222H32.8514Z" fill="black"/>
            <path d="M38.1076 41C37.6655 41 37.2693 40.9238 36.9189 40.7714C36.5685 40.6105 36.289 40.3819 36.0805 40.0855C35.8803 39.7807 35.7802 39.4123 35.7802 38.9805C35.7802 38.5486 35.8803 38.1888 36.0805 37.9009C36.289 37.6045 36.5727 37.3843 36.9314 37.2404C37.2985 37.088 37.7156 37.0118 38.1827 37.0118H39.8845V36.6561C39.8845 36.3598 39.7928 36.1184 39.6092 35.9322C39.4257 35.7374 39.1337 35.64 38.7333 35.64C38.3412 35.64 38.0493 35.7332 37.8574 35.9195C37.6655 36.0973 37.5404 36.3301 37.482 36.618L36.0304 36.1227C36.1305 35.8009 36.289 35.5088 36.5059 35.2463C36.7312 34.9753 37.0273 34.7594 37.3944 34.5985C37.7698 34.4292 38.2244 34.3445 38.7583 34.3445C39.5759 34.3445 40.2224 34.5519 40.6979 34.9669C41.1734 35.3818 41.4112 35.983 41.4112 36.7705V39.1202C41.4112 39.3742 41.5279 39.5012 41.7615 39.5012H42.2621V40.8222H41.2109C40.9023 40.8222 40.6478 40.746 40.4476 40.5936C40.2474 40.4411 40.1473 40.2379 40.1473 39.9839V39.9712H39.9096C39.8762 40.0728 39.8011 40.2083 39.6843 40.3776C39.5675 40.5385 39.384 40.6825 39.1337 40.8095C38.8835 40.9365 38.5414 41 38.1076 41ZM38.3829 39.7045C38.8251 39.7045 39.1838 39.5817 39.4591 39.3361C39.7427 39.0821 39.8845 38.7476 39.8845 38.3327V38.2057H38.2953C38.0034 38.2057 37.774 38.2692 37.6071 38.3962C37.4403 38.5232 37.3568 38.7011 37.3568 38.9297C37.3568 39.1583 37.4444 39.3446 37.6196 39.4885C37.7948 39.6325 38.0493 39.7045 38.3829 39.7045Z" fill="black"/>
            <path d="M43.4148 40.8222V34.5223H44.9665V35.3479H45.1917C45.2918 35.1277 45.4795 34.9203 45.7548 34.7255C46.0301 34.5223 46.4472 34.4207 47.0062 34.4207C47.49 34.4207 47.9113 34.535 48.27 34.7636C48.6371 34.9838 48.9207 35.2929 49.1209 35.6908C49.3211 36.0803 49.4212 36.5376 49.4212 37.0626V40.8222H47.8446V37.1896C47.8446 36.7154 47.7278 36.3598 47.4942 36.1227C47.2689 35.8856 46.9436 35.767 46.5181 35.767C46.0343 35.767 45.6589 35.9322 45.3919 36.2624C45.125 36.5842 44.9915 37.0372 44.9915 37.6214V40.8222H43.4148Z" fill="black"/>
            <path d="M54.0413 41C53.4406 41 52.8942 40.873 52.402 40.619C51.9182 40.3649 51.5344 39.9966 51.2508 39.5139C50.9672 39.0313 50.8254 38.447 50.8254 37.7612V37.5833C50.8254 36.8975 50.9672 36.3132 51.2508 35.8305C51.5344 35.3479 51.9182 34.9796 52.402 34.7255C52.8942 34.4715 53.4406 34.3445 54.0413 34.3445C54.6336 34.3445 55.1424 34.4503 55.5679 34.662C55.9933 34.8737 56.3354 35.1658 56.594 35.5384C56.8609 35.9025 57.0361 36.3174 57.1195 36.7831L55.5929 37.1134C55.5595 36.8594 55.4845 36.6307 55.3677 36.4275C55.2509 36.2243 55.084 36.0634 54.8671 35.9449C54.6586 35.8263 54.3958 35.767 54.0788 35.767C53.7618 35.767 53.474 35.839 53.2154 35.983C52.9651 36.1184 52.7649 36.3259 52.6148 36.6053C52.4729 36.8763 52.402 37.2108 52.402 37.6087V37.7358C52.402 38.1337 52.4729 38.4724 52.6148 38.7519C52.7649 39.0228 52.9651 39.2303 53.2154 39.3742C53.474 39.5097 53.7618 39.5774 54.0788 39.5774C54.5543 39.5774 54.913 39.4547 55.1549 39.2091C55.4052 38.9551 55.5637 38.6248 55.6305 38.2184L57.1571 38.5867C57.0486 39.0355 56.8609 39.4462 56.594 39.8188C56.3354 40.1829 55.9933 40.4708 55.5679 40.6825C55.1424 40.8942 54.6336 41 54.0413 41Z" fill="black"/>
            <path d="M61.2857 41C60.6684 41 60.1219 40.8688 59.6464 40.6063C59.1793 40.3353 58.8122 39.9585 58.5453 39.4758C58.2867 38.9847 58.1574 38.4089 58.1574 37.7485V37.596C58.1574 36.9356 58.2867 36.364 58.5453 35.8814C58.8039 35.3902 59.1668 35.0134 59.6339 34.7509C60.1011 34.48 60.6433 34.3445 61.2606 34.3445C61.8696 34.3445 62.3994 34.4842 62.8498 34.7636C63.3003 35.0346 63.6507 35.4156 63.901 35.9068C64.1512 36.3894 64.2764 36.9525 64.2764 37.596V38.1422H59.7591C59.7757 38.574 59.9342 38.9254 60.2346 39.1964C60.5349 39.4674 60.9019 39.6029 61.3357 39.6029C61.7779 39.6029 62.1032 39.5055 62.3118 39.3107C62.5203 39.116 62.6788 38.9 62.7873 38.6629L64.0761 39.3488C63.9594 39.569 63.7883 39.8103 63.5631 40.0728C63.3462 40.3268 63.0542 40.547 62.6872 40.7333C62.3201 40.9111 61.8529 41 61.2857 41ZM59.7716 36.9483H62.6746C62.6413 36.5842 62.4953 36.292 62.2367 36.0719C61.9864 35.8517 61.6569 35.7416 61.2481 35.7416C60.8227 35.7416 60.4848 35.8517 60.2346 36.0719C59.9843 36.292 59.83 36.5842 59.7716 36.9483Z" fill="black"/>
          </svg>
        </a>
        <div className={styles.menu}>
          <div className={styles.mobileMenu}>
            <div className={styles.menuItem}><Link className={styles.link} to={'/#company'}>Company</Link></div>
            <div className={styles.menuItem}><a className={styles.link} href={'/#services'}>Services</a></div>
            <div className={styles.menuItem}><a className={styles.link} href={'/#expertise'}>Expertise</a></div>
          </div>
          <div className={styles.menuItem}><Link className={`${styles.link} ${styles.button}`} to={'/#contacts'} >Get started</Link></div>
        </div>
      </div>
    </header>
  )

}

export default Header
